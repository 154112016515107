@import '~styles';

.container {
  height: fit-content;
  width: 300px;
  margin-top: -$spacer-m;

  @include responsiveMedium() {
    width: 100%;
  }

  &.column {
    @include responsiveLarge() {
      width: 100%;
    }
  }
}

.templates {
  position: relative;
  margin-top: $spacer-m;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $spacer-xs;
  justify-content: center;
  max-height: 400px;
  overflow-x: scroll;

  @include responsiveMedium() {
    grid-template-columns: 1fr 1fr 1fr;
    max-height: 200px;
  }

  &.column {
    @include responsiveLarge() {
      grid-template-columns: 1fr 1fr 1fr;
      max-height: 200px;
    }
  }
}

.button-container {
  height: 80px;
  border-radius: 12px;
  background: $color-light;
  border: $border-gray-light;

  &:hover,
  &:focus {
    outline: none;
  }

  &:hover {
    border: $border-dark;
  }
}

.template-illustration {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.illustration-background {
  background-color: $color-primary;
}

.themes {
  margin-top: $spacer-m;
  display: grid;
  grid-template-columns: 1fr;
  gap: $spacer-xs;
  max-height: 450px;
  overflow-x: scroll;

  @include responsiveMedium() {
    grid-template-columns: 1fr 1fr;
    max-height: 250px;
  }
  &.column {
    @include responsiveLarge() {
      grid-template-columns: 1fr 1fr;
      max-height: 250px;
    }
  }
}

.theme {
  width: 100%;
  margin: auto;
  text-align: left;
  border-radius: 10px;
  padding: $spacer-l 40px;
  background-color: $color-light;
  border: $border-black-light;

  &:hover,
  &:focus {
    outline: none;
    background-color: $color-light-black;
  }
}

.selected {
  background-color: $color-light-black;
  border: $border-dark-light;
}

.theme-colors {
  display: flex;
  margin-bottom: $spacer-xs;
}

.theme-color {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin-right: -$spacer-xs;
}

.theme-text {
  line-height: 2rem;
}

$indexes: 1, 2, 3, 4;

@each $index in $indexes {
  .theme-index-#{$index} {
    z-index: $index;
  }
}
